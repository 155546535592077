import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from "react-helmet";
import Layout from 'components/Layout';
import BlogPost from 'components/pages/BlogPost';
import { Image } from 'components/pages/BlogPost/styled';
import { ImgGatsbyBackground } from 'components/wrappers';
import styled, { css } from 'styled-components';
import { blogPosts } from 'common/blogPostsFeatured';
import BlogBaseCard from 'components/blog/BaseCard';
import BlogSidebar from 'components/blog/BlogSidebar';
import {PostsWrapper} from 'components/pages/Blog/styled';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import FacebookDefaultIcon from 'images/social-share-icons/facebook-white.png';
import TwitterDefaultIcon from 'images/social-share-icons/twitter-white.png';
import LinkedinDefaultIcon from 'images/social-share-icons/linkedin-white.png';
import BlogOptin from 'components/blog/BlogOptin';
import {Clear, BlogRow, ShareButton, ReadNow, DateText, TitleText, UnorderedList, OrderedList, FirstTitle, BottomShare, SectionFull, OptinSection, ColumnHalf, ColumnHalfLast} from 'components/blog/SingleBlogStyle';

import ShipkooDoorToDoor from 'images/blog-images/shipkoo-door-to-door.png';
import ShipkooShippingRoute from 'images/blog-images/shipkoo-shipping-route.png';
import ShipkooDoorToDoorFeatured from 'images/featured-image/shipkoo-door-to-door-featured.png';


interface OwnProps {
  location: {
    href: string;
  };
}

const BlogArticle = ({ location }: OwnProps) => {
  const data = useStaticQuery(graphql`
    query ShipkooDoorToDoorImg {
      file(relativePath: { eq: "shipkoo-door-to-door.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const BackgroundImage = () => <ImgGatsbyBackground fluid={data.file.childImageSharp.fluid} alt="shipkoo door to door trucking solution"/>;
  const { href } = location;
  const intl = useIntl()
  return (
    <Layout>
      <Helmet 
        // title="Shipkoo Door to Door Trucking Solution from China to Europe | Shipkoo"
        title={intl.formatMessage({ id: "blog-post-meta-title-shipkoo-door-to-door" })}
         meta={
          [
            {
              name: `description`,
              content: "",
            },
            {
              property: `og:title`,
              // content: "Shipkoo Door to Door Trucking Solution from China to Europe",
              content: intl.formatMessage({ id: "blog-post-meta-title-shipkoo-door-to-door" }),
            },
            {
              property: `og:description`,
              content: "",
            },
            {
              property: `og:image`,
              content: ShipkooDoorToDoorFeatured,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              name: `twitter:card`,
              content: `summary`,
            },
          ] as JSX.IntrinsicElements['meta'][]
        }
      />
      <BlogPost
        location={location}
        id="shipkoo-door-to-door-trucking-solution-from-china-to-europe"
        title="Shipkoo Door to Door Trucking Solution from China to Europe"
        date="2020-11-30"
        author="Joe Zhang"
        Background={BackgroundImage}
      >
        <BlogRow>
          <BlogSidebar/>
          <div className="column-half last">

            <p>
              It has been widely reported that there are significant backlogs at Chinese airports and destination airports due to the COVID-19 and limited air freight capacity. During the pandemic, a lot of packages are sent from China every day, so our special lines can quickly ship goods to destination countries without strict time limit requirements (e.g. FedEx). Many express fulfillment centers like Hong Kong DHL warehouses are suffering from overload during the pandemic. Often many packages are in a queue and it takes 15 days for a package to scan into the system.
            </p>

            <p>
              Shipkoo is continuously working on developing new shipping routes and providing various shipping options, which is the only way to ensure your products can reach your customers in time. The world of logistics is changing and you may wish to consider alternative solutions such as China to Europe rail freight as a logistics solution for your goods. Did you also know that road freight and trucking out of China into Europe is also an available service? In terms of shipping cost, Shipkoo’s new trucking service is 40% less than air freight, while the transit time remains the same or even faster.
            </p>

            <Image src={ShipkooDoorToDoor} className="" alt="shipkoo door to door trucking solution"/>

            <h5>
              <strong>
                Door to Door trucking solution - China to Europe
              </strong>
            </h5>
            
            <p>
              There are several  commercial benefits when considering road freight and trucking solutions from China to Europe.
            </p>

            <p>
              Airfreight is a challenging market currently due to COVID-19 and a door to door trucking solution could provide significant cost savings with extensive coverage across China and Europe. Road freight has faster transit times than ocean freight and the flexible operations and frequent departures could also resolve supply chain challenges currently being faced by exporters including more predictable costs. In conclusion, you will find our trucking solution is the most cost-effective and fastest shipping solution during these special times.
            </p>

            <Image src={ShipkooShippingRoute} alt="shipkoo shipping route"/>

            <h5>
              <strong>
                Shipping route
              </strong>
            </h5>

            <p>
              The whole shipping transit time will be 12-16 working days from door to door. It will be15 days faster than the railway and we have more than 20 vehicles sent to the UK, Netherlands, Germany, Lishi, Poland and Hungary weekly. The new shipping route is frequently used by most of our FBA sellers who want to send products from China to Europe. Free feel to contact our shipping specialists for more information regarding our new route.
            </p>

            <BottomShare>
              <div className="column-half">
                <p>Share the article</p>
              </div>
              <div className="column-half last">
                <FacebookShareButton url={href}>
                  <ShareButton><img src={FacebookDefaultIcon} alt="Facebook Icon" /> <span>Share</span></ShareButton>
                </FacebookShareButton>
                <TwitterShareButton url={href}>
                  <ShareButton><img src={TwitterDefaultIcon} alt="Twitter Icon" /> <span>Tweet</span></ShareButton>
                </TwitterShareButton>
                <LinkedinShareButton url={href}>
                  <ShareButton><img src={LinkedinDefaultIcon} alt="Linked Icon" /> <span>Share</span></ShareButton>
                </LinkedinShareButton>
              </div>
              <Clear></Clear>
            </BottomShare> 
          </div>  
          <Clear></Clear>
       </BlogRow> 
      </BlogPost>
      <SectionFull>
        <BlogRow>
          <h2>More From Shipkoo</h2>
          <PostsWrapper>
            {blogPosts.map(post => (
              <BlogBaseCard key={`${post.title}-${post.id}`} post={post} />
            ))}
          </PostsWrapper>
        </BlogRow>
      </SectionFull>
      <BlogOptin />
    </Layout>
  );
};

export default BlogArticle;